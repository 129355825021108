//default lang, map api key, firebase config, theme settings default color dark or light,
// base URL of your admin panel

export const BASE_URL = process.env.REACT_APP_BASE_URL;
// API URL
export const API_URL = BASE_URL + "/api/v1/";
export const MAP_API = process.env.REACT_APP_MAP_API;

export const mode = "dev"; // "live" or "dev". make it "dev" when in development

export const INSTAGRAM_LINK = process.env.REACT_APP_INSTAGRAM_LINK;
export const WHATSAPP_LINK =
  "https://wa.me/" + process.env.REACT_APP_WHATSAPP_NUMBER;
export const FACEBOOK_LINK = process.env.REACT_APP_FACEBOOK_LINK;
export const TWITTER_LINK = process.env.REACT_APP_TWITTER_LINK;
export const LINKEDIN_LINK = process.env.REACT_APP_LINKEDIN_LINK;
export const LIGHT_LOGO = "/images/logolight.png";
export const DARK_LOGO = "/images/logolight.png";

export const PLAY_STORE_LINK = process.env.REACT_APP_PLAY_STORE_LINK;
export const APPLE_STORE_LINK = process.env.REACT_APP_APPLE_STORE_LINK;

export const GOOGLE_MAP = "https://maps.googleapis.com/maps/api/js";
let config = {
  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "de", "es"],
  demo: true, // Bool
  defaultLanguage: "en",
};
export default config;


/** Only modify above given config
 * Do not touch below code
 * */
// all possible order statuses
export const order_statues = [
  "awaiting",
  "confirmed",
  "cancelled",
  "rescheduled",
  "completed",
  "started"
];
export const formData = new FormData();
export var myHeaders = new Headers();
export const token = localStorage.getItem("Token");

//function for load google map
export function loadAsyncScript(src) {
  return new Promise((resolve) => {
    // Check if the script with the given source already exists
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      // If the script already exists, resolve the promise immediately
      resolve(existingScript);
    } else {
      // Otherwise, create a new script element and load the script
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    }
  });
}

// Function to handle open drawer
export function handleOpen(setDrawerOpen) {
  setDrawerOpen(true);
}

// Function to handle close drawer
export function handleClose(setDrawerOpen) {
  setDrawerOpen(false);
}
